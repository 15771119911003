// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../abstracts/Montserrat-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../abstracts/Montserrat-Bold.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Montserrat';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\"); }\n\n@font-face {\n  font-family: 'Montserrat-Bold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\"); }\n\n.block-image {\n  width: 100%;\n  height: auto; }\n", "",{"version":3,"sources":["webpack://src/scss/abstracts/_fonts.scss","webpack://src/scss/components/_block-image.scss"],"names":[],"mappings":"AAAA;EACA,yBAAyB;EACzB,+DAAqD,EAAA;;AAGrD;EACE,8BAA8B;EAC9B,+DAAkD,EAAA;;ACJpD;EACE,WAAW;EACX,YAAY,EAAA","sourcesContent":["@font-face {\nfont-family: 'Montserrat';\nsrc: url('Montserrat-Regular.ttf') format('truetype');\n}\n\n@font-face {\n  font-family: 'Montserrat-Bold';\n  src: url('Montserrat-Bold.ttf') format('truetype');\n  }\n\n@mixin font-bold {\n  font-family: ('Montserrat-Bold', 'Roboto', 'Sans Serif');\n  font-weight: bold;\n  font-style: normal;\n  font-size: 16px;\n  line-height: 20px;\n  color: #000000;\n  align-items: center;\n  text-align: center;\n  letter-spacing: 0.2em;\n}\n\n@mixin font-normal {\n  font-family: ('Montserrat', 'Roboto', 'Sans Serif');\n  font-weight: normal;\n  font-style: normal;\n  font-size: 22px;\n  line-height: 34px;\n  color: #000000;\n  align-items: center;\n  text-align: center;\n  letter-spacing: 0.05em;\n}","@import '../abstracts/fonts';\n@import '../abstracts/colours';\n\n.block-image {\n  width: 100%;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
